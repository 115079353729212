@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: whitesmoke;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div,
p,
a,
li,
span,
.Cell,
.Time {
  font-family: 'Karla', sans-serif;
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Header {
  max-width: 1300px;
  text-align: left;
  padding-top: 30px;
  margin: 0 auto;
  margin-top: 30px;
  position: relative;
}

.BookingWidget {
  padding: 30px;
  padding-left: 90px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  max-width: 1300px;
}

.Times {
  top: 62px;
  left: 29px;
  width: 60px;
}

.time {
  height: 34px;
  box-sizing: border-box;
}

.Times .time.sharp {
  border-top: 1px solid lightgrey;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding-right: 6px;
  font-size: 14px;
  color: black;
}

.Times .time.half {
  border-top: 1px dashed lightgrey;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding-right: 6px;
  color: grey;
  font-size: 12px;
}

.Column {
  width: 100% !important;
}

.Cell {
  height: 34px;
  text-align: center;
  border-top: 1px solid lightgrey;
  border-left: 8px solid rgb(136, 91, 73);
  background: rgb(239, 255, 244);
  overflow: hidden;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: white;
  cursor: pointer;
}

.Cell:empty {
  border-left: 8px solid rgb(184, 243, 106);
}

.Cell.half {
  border-left: 8px solid rgb(180, 129, 109);
  background: whitesmoke;
}

.Cell.half:empty {
  border-left: 8px solid rgb(129, 209, 24);
}

button {
  margin-bottom: -2px;
}
button:hover {
  opacity: 1;
}

.text-grey {
  color: grey;
}

.button-active {
  background: white;
  color: black;
  opacity: 1;
  color: black;
}

.Logo {
  position: absolute;
  top: -18px;
  left: 13px;
  width: 50px;
  opacity: 0.2;
  margin: 0 auto;
  margin-bottom: 20px;
}

.Logo:hover {
  opacity: 1;
}

.Footer {
  padding: 20px 30px 30px;
  text-align: center;
  font-size: 14px;
}

.bg-light {
  background: whitesmoke;
}
.bg-dark {
  background: #017762;
}

.text-red {
  color: tomato;
}

.bg-red {
  background: salmon;
  color: white;
}

.idleOverlay {
  position: fixed;
  z-index: 9999999;
  top: 0; left: 0; bottom: 0; right: 0;
  background: rgba(0,0,0,0.7);
  text-align: center;
}

.idleOverlay h3 {
  color: white;
  font-size: 30px;
  max-width: 600px;
  padding: 30px 50px;
  margin: 0 auto;
  margin-top: 10%;
}

.idleOverlay button {
  background:white;
  font-size: 20px;
  color: black;
  margin-top: 20px;
  border-radius: 5px;
  padding: 10px 30px;
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.13);
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.13);
}